
.certificate-list-content{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .top{
        margin: 20px 0;
        position: relative;
        .btn-back{
            position: absolute;
            top: -12px;
            right: 20px;
        }
    }
    .search-content{
        margin-bottom: 20px;
    }
    ::v-deep .el-table th>.cell{
        padding-right: 0;
    }
    ::v-deep .el-table-column--selection .cell{
        padding-right: 0;
    }
}
